import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";

function MinergyMax() {
  const navigate = useNavigate();

  return (
    <div className="App" id="Environment">
      <Nav />
      <section className="text-black body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img
                  className="mx-auto px-10 max-h-full"
                  src="images/product_minergy_max.png"
                  alt="Eco-Bot"
                />
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold  py-5">
                  MINERGY MAX
                </h1>
                <h4 className="text-xl pb-3  font-semibold">
                  Enriched with Amino Acids
                </h4>
                {/* <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Vibrio
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Organic Loads
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Toxic Gases
                  </span>
                </div> */}

                <h2 className="text-2xl font-bold  mb-2">Benefits</h2>
                <ul className="list-square pl-5 leading-8">
                  <li>Ensures protection from mineral deficiencies.</li>
                  <li>Maintains osmotic equilibrium.</li>
                  <li>
                    Strengthens the exoskeleton and supports bone development in
                    shrimp and fish.
                  </li>
                  <li>Boosts immune defenses and protective responses.</li>
                  <li>
                    Stimulates plankton growth by nutrient enrichment in the
                    pond.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold  mb-2 mt-5">
                  Dosage and method of application
                </h2>
                <p>
                  <strong>Pond Preparation:</strong> 30 kg/ha.
                </p>
                <p class="pt-2">
                  <strong>Culture:</strong> 10-20 kg/ha.
                </p>
                <p class="pt-2">
                  Apply evenly throughout the pond, dosage may vary depending on
                  stocking density, ABW etc.
                </p>
                <p class="pt-2 font-bold">
                  (or as advised by an Aqua Consultant)
                </p>
                <h2 className="text-2xl font-bold  mt-4">Composition</h2>
                <p className="py-3 pr-10">
                  A unique consortium of macro minerals including Calcium (20%),
                  Magnesium (13%), Potassium (15%), and Sodium (12%), and trace
                  minerals viz. zinc, copper, manganese, cobalt, selenium,
                  iodine, and iron. Enriched with amino acids and a growth
                  modulator suitable for low and high salinity shrimp and fish
                  culture.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class=" ">
        <h2 class="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44">
          {" "}
          Other Products
        </h2>
      </div>
      <div class="flex flex-wrap xl:ml-10 2xl:px-40">
        <div class="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"
            onClick={() => navigate("/Teaseedpowder")}
          >
            <img class="mx-auto " src="images/product_tea_seed_powder1.png" />
            <h3 class="text-xl font-bold  text-center">Tea Seed Powder</h3>
            {/* <div class="px-6 lg:px-3 pt-4 ">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Wild Fishes
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Crabs
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Snails
              </span>
            </div> */}
          </div>
        </div>
        {/* <div class="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/Alkasense")}>
            <img onClick={() => navigate("/Alkasense")}class="mx-auto " src="images/Dr_Grow_Alkasense (1).png" />
            <h3 class="text-xl font-bold  text-center">
              Alkasense
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               pH Control
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Alkalinity
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               Water Quality
              </span>
             
            </div>
          </div>
        </div> */}

        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Gutbot")}
          >
            <img
              onClick={() => navigate("/Gutbot")}
              class="mx-auto"
              src="images/Dr_Grow_Gut_Bot.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold   text-center mb-2">
                <span class="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Digestion
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>

        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Zeoforce")}
          >
            <img
              onClick={() => navigate("/Zeoforce")}
              class="mx-auto"
              src="images/product_zeoforce.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold   text-center mb-2">
                <span class="lust ">Zeo-Force</span>
              </h3>

              {/* <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves Do
              </span>
              <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinergyMax;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<PondBp />);

import React from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";
// import PondBp from "./PondBP";

export default function Product() {
  const navigate = useNavigate();

  return (
    <div class="flex flex-row px:10 xl:px-4 2xl:px-10">
      <div class="grid gap-6 md:grid-cols-3 grid-cols-1 items-center xl:px-10 2xl:px-10">
        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Minergymax")}
          >
            <img
              onClick={() => navigate("Minergymax")}
              class="mx-auto"
              src="images/product_minergy_max.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold text-center mb-2">
                <span class="lust ">Minergy Max</span>
              </h3>

              {/* <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves DO
              </span>
              <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>
        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("PS")}
          >
            <img
              onClick={() => navigate("PS")}
              class="mx-auto"
              src="images/product_ps_no1.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold text-center mb-2">
                <span class="lust ">PS No.1</span>
              </h3>

              {/* <span
                onClick={() => navigate("Aquapromsl")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Green Colonies
              </span>
              <span
                onClick={() => navigate("Aquapromsl")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span>
              <span
                onClick={() => navigate("Aquapromsl")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                All-Weather
              </span> */}
            </div>
          </div>
        </div>
        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Ecobot")}
          >
            <img
              onClick={() => navigate("Ecobot")}
              class="mx-auto"
              src="images/product_ecobot.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold text-center mb-2">
                <span class="lust">Eco Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Organic Loads
              </span>
              <span
                onClick={() => navigate("Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span> */}
            </div>
          </div>
        </div>

        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Teaseedpowder")}
          >
            <img
              onClick={() => navigate("Teaseedpowder")}
              class="mx-auto"
              src="images/product_tea_seed_powder1.png"
              alt=""
            />
            <div>
              {/* <button> */}
              <h3 class="text-xl font-bold  text-center mb-2">
                <span class="lust font-bold">Tea Seed Powder</span>
              </h3>

              {/* <a class="text-white">
                <span
                  onClick={() => navigate("Teaseedpowder")}
                  class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Wild Fishes
                </span>
              </a>
              <span
                onClick={() => navigate("Teaseedpowder")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Crabs
              </span>
              <span
                onClick={() => navigate("Teaseedpowder")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2 "
              >
                Snails
              </span> */}
              {/* </button> */}
            </div>
          </div>
        </div>

        {/* <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Alkasense")}
          >
            <img
              onClick={() => navigate("Alkasense")}
              class="mx-auto"
              src="images/Dr_Grow_Alkasense (1).png"
              alt=""
            />
            <div>
            
              <div>
                <h3 class="text-xl font-bold  text-center mb-2 ">
                  <span class="lust ">Alkasense</span>
                </h3>
              </div>

              <div>
                <span
                  onClick={() => navigate("Alkasenseos")}
                  class="inline-block  bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  pH Control
                </span>
                <span
                  onClick={() => navigate("Alkasense")}
                  class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Alkalinity
                </span>
                <span
                  onClick={() => navigate("Alkasense")}
                  class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
                >
                  Water Quality
                </span>

              </div>
            
            </div>
          </div>
        </div> */}

        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Gutbot")}
          >
            <img
              onClick={() => navigate("Gutbot")}
              class="mx-auto"
              src="images/product_gutbot.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold text-center mb-2">
                <span class="lust ">Gut Bot</span>
              </h3>

              {/* <span
                onClick={() => navigate("Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Digestion
              </span>
              <span
                onClick={() => navigate("Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span> */}
            </div>
          </div>
        </div>

        <div>
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("Zeoforce")}
          >
            <img
              onClick={() => navigate("Zeoforce")}
              class="mx-auto"
              src="images/product_zeoforce.png"
              alt=""
            />

            <div>
              <h3 class="text-xl font-bold text-center mb-2">
                <span class="lust ">Zeo-Force</span>
              </h3>

              {/* <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves DO
              </span>
              <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Product />);
